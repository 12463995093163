import React, { useState, useEffect } from 'react'
import Fetch from '../fetch/axios'
import { List, Checkbox, Card, Table, Descriptions, Button, Modal, Upload, message } from 'antd'
import './RequestDetail.css'
const RequestDetail = (props) => {
  const [data, setData] = useState({ fields: [] });
  const [historyList, setHistoryList] = useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [showEditAttachments, setShowEditAttachments] = useState(false);
  const [canEditAttachments, setCanEditAttachments] = useState(false);
  const [checkedAttachments, setCheckedAttachments] = useState([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const [currentRequestId, setCurrentRequestId] = useState('');
  const [currentLogId, setCurrentLogId] = useState(null);
  // 获取请求的详情
  useEffect(() => {
      const tmpFunc = async () => {
        let search = props.history.location.search;
        search = search.substr(1, search.length);
        const searchObj = JSON.parse(decodeURI(search));
        const id = searchObj;
        const response = await Fetch.getCurrentUser();
        if (response && response.code == 0 && response.data && response.data.Id)
          setCurrentUserId(response.data.Id);
        Fetch.getBorrowRequestDetail({ id })
             .then((res) => { if (res.success === true) { setData(res.data); } });
      };
      tmpFunc();
  }, []);
  // 获取请求的日志
  useEffect(() => {
    let search = props.history.location.search
    search = search.substr(1, search.length)
    const searchObj = JSON.parse(decodeURI(search))
    const id = searchObj
    Fetch.getBorrowRequestHistory(
      { requestId: id },
      {
        pageIndex: 1,
        pageSize: 2000,
      }
    ).then((res) => {
      if (res.code === 0) {
        setHistoryList(res.data.rows)
      }
    })
  }, []);
  const loadRequestHistory = async () => {
    let search = props.history.location.search;
    search = search.substr(1, search.length);
    const searchObj = JSON.parse(decodeURI(search));
    const requestId = searchObj;
    const response = await Fetch.getBorrowRequestHistory( { requestId }, { pageIndex: 1, pageSize: 2000 } );
    if (response.code !== 0) return;
    setHistoryList(response.data.rows);
    return response.data.rows;
  };
  const exportExcel = () => {
    let search = props.history.location.search
    search = search.substr(1, search.length)
    const searchObj = JSON.parse(decodeURI(search))
    const id = searchObj
    Fetch.exportReqExcel({
      id,
    }).then((res) => {
      const blob = new Blob([res]);
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `档案借阅详情_${new Date().getTime()}.xls`;
      a.href = blobUrl;
      a.click();
    })
  };
  const handleCheckedAttachmentsChange = (item) => {
    const checked = checkedAttachments.includes(item.Id);
    if (checked) {
      setCheckedAttachments(checkedAttachments.filter(id => id !== item.Id));
    } else {
      setCheckedAttachments([...checkedAttachments, item.Id]);
    }
  };
  const openAttachmentModal = (item) => {
    setCurrentRequestId(item.RequestId);
    setCurrentLogId(item.Id);
    setCanEditAttachments(item.UserId === currentUserId);
    setShowEditAttachments(false);
    setAttachmentsList(item.Attachments);
    setShowAttachments(true);
  };
  const deleteAttachments = ()=>{
    if (checkedAttachments.length <= 0) {
      message.error('请先选择要删除的附件.');
      return;
    }
    const tmpAttachmentsList = attachmentsList.filter((t) => !checkedAttachments.includes(t.Id));
    if (tmpAttachmentsList.length <= 0) {
      message.error('请至少保留一个附件.');
      return;
    }
    Modal.confirm({
      title: '确认删除选择的附件?',
      centered: true,
      onOk: async () => {
        const response = await Fetch.deleteFile(checkedAttachments);
        if (response.code === 0) {
          setAttachmentsList(tmpAttachmentsList);
          setCheckedAttachments([]);
          await loadRequestHistory();
          message.success("删除成功");
        }
        else
          message.error(`删除失败: [${response.message}]`);
      }
    })
  };
  const beforeUpload = (file) => {
    // 限制文件大小不超过100MB
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error('文件太大，不能超过50MB!');
      return isLt50M;
    }
    const result = isLt50M;
    // this.props.setSpinning(result);
    return result;
  };
  // 上传完成回调
  const onUploadChange = async (info) => {
    if (info.file.response && info.file.response.code === 0) {
      const requestHis = await loadRequestHistory();
      const attachments = requestHis.filter((t) => t.Id === currentLogId)[0].Attachments;
      setAttachmentsList(attachments);
      setCheckedAttachments([]);
      message.success(info.file.response.message);
    } else if (info.file.response && info.file.response.code === 500) {
      message.error(info.file.response.message);
    } else if (info.file.response && info.file.response.error) {
      if (info.file.response.message == null)
        info.file.response.message = 'No Message.';
      message.error(`${info.file.response.status} ${info.file.response.error}, ${info.file.response.message}`)
    } else if (info.file.status === 'error') {
      message.error('网络错误, 上传失败。（提示：可能文件超过限制大小）');
    }
  };
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <div style={{ margin: '10vh auto', maxWidth: '50%' }}>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="创建人">
            {data.createUserName}
          </Descriptions.Item>
          <Descriptions.Item label="借阅人">
            {data.BorrowUserId}
          </Descriptions.Item>
          <Descriptions.Item label="单位名称" span={2}>
            {data.unitName}
          </Descriptions.Item>
          <Descriptions.Item label="创建时间">
            {data.CreateTime}
          </Descriptions.Item>
          <Descriptions.Item label="完结时间">
            {data.ReturnedTime}
          </Descriptions.Item>
        </Descriptions>
        <Card title="请求列表"
              style={{ width: '100%' }}
              extra={ <Button type="primary" onClick={exportExcel}>导出列表</Button> }>
          <Table dataSource={data.archDetailedInfos}
                 style={{overflowY: 'auto', maxHeight: '500px'}}
            columns={[
              {
                title: '序号',
                dataIndex: 'BorrowSn',
                key: 'BorrowSn',
                align: 'center',
              },
              {
                title: '位置',
                dataIndex: 'cellFullName',
                key: 'cellFullName',
                align: 'center',
              },
              {
                title: '借阅状态',
                render: record => (
                  <span style={record.isReturn === 0 ? {color: '#1890ff'} : record.isReturn === 1 ? {color: '#ff9900'} : record.isReturn === -1 ? {color: '#fc0000'} : record.isReturn === 4 ? {color: '#07ba2e'} : {color: '#fc0000'}} >{record.isReturn === 0 ? '审核中' : record.isReturn === 1 ? '借阅中' : record.isReturn === -1 ? '已驳回' : record.isReturn === 4 ? '已归还' : '不归还'}</span>
                ),
                align: 'center',
              },
              ...data.fields.map((item) => ({
                title: item.Name,
                dataIndex: item.Id,
                key: item.Id,
                align: 'center',
              })),
            ]}
            rowKey={(item) => item.Id}
            pagination={{ pageSize: 2000, hideOnSinglePage: true }}
            scroll={{ x: 'max-content' }}
          />
        </Card>
        <Card title="请求详情" style={{ width: '100%' }}>
          <List itemLayout="horizontal"
                dataSource={historyList}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta title={item.Content} />
                    {(item.Attachments && item.Attachments.length > 0) ? (<Button type="link" onClick={ ()=> { openAttachmentModal(item); } } >查看附件</Button>) : null}
                    <div style={{ marginLeft: '5px' }}>{item.Time}</div>
                  </List.Item>)} />
        </Card>
        {showAttachments ? (
          <Modal visible={showAttachments} centered={true} title="附件详情"
                 cancelButtonProps={{ style: { display: 'none' } }}
                 footer={[
                  <Button key='1' type="default" style={{ float: 'left', display: (canEditAttachments && !showEditAttachments) ? 'block' : 'none' }} onClick={()=>{ setCheckedAttachments([]); setShowEditAttachments(true); }}>编辑</Button>,
                  <Upload key='2' action={`${window.serverIp}:${window.serverPort}/api/v1/file/uploadBorrowAttachment?requestId=${currentRequestId}&logId=${currentLogId}`}
                          withCredentials={true} beforeUpload={beforeUpload} onChange={onUploadChange}>
                      <Button type="primary" style={{ float: 'left', marginRight: '10px', display: showEditAttachments ? 'inline-block' : 'none' }} onClick={()=>{  }}>添加</Button>
                  </Upload>,
                  <Button key='3' type="danger" disabled={checkedAttachments.length <= 0} style={{ float: 'left', display: showEditAttachments ? 'block' : 'none' }} onClick={()=>{ deleteAttachments(); }}>删除</Button>,
                  <Button key='4' type="primary" style={{ display: showEditAttachments ? 'none' : 'inline-block' }} onClick={()=>{ setShowAttachments(false); }}>确定</Button>,
                  <Button key='5' type="default" style={{ display: showEditAttachments ? 'inline-block' : 'none' }} onClick={()=>{ setShowEditAttachments(false); }}>取消</Button>
                 ]}
                 onOk={()=>{ setShowAttachments(false); }}
                 onCancel={()=>{ setShowAttachments(false); }}>
            <List itemLayout="horizontal" dataSource={attachmentsList}
                  style={{overflowY: 'auto', maxHeight: '300px'}}
                  renderItem={(item) => (
              <List.Item>
                <span style={{ display: 'flex' }}>
                  <Checkbox style={{ marginRight: '10px', display: showEditAttachments ? 'block' : 'none'}} checked={checkedAttachments.includes(item.Id)} onChange={() => { handleCheckedAttachmentsChange(item); }} />
                  <a href={`${window.serverIp}:${window.serverPort}/api/v1/file/download?id=${item.Id}&disposition=inline`} target={'_blank'} title={`上传时间: ${item.UploadTime}`}>{item.Name}</a>
                </span>
                <a style={{ marginRight: '10px' }} href={`${window.serverIp}:${window.serverPort}/api/v1/file/download?id=${item.Id}`}>下载</a>
              </List.Item>)} />
          </Modal>
        ) : null}
      </div>
    </div>
  )
}
export default RequestDetail
